
import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiPackageDetail, apiPackageMemberPrice, apiSetPackageMemberPrice } from '@/api/goods'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddBrand extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    form = {
        name: '',
        image: '',
    }
	
	items:any = []

    handleSave() {
		let params: { package_id: any, items: any } = { package_id: '', items: [] };
		params.package_id = this.id
		params.items = this.items
		console.log( params )
		apiSetPackageMemberPrice(params).then(res=>{
			this.$router.go(-1)
		})
    }

	getPackagePrice(){
		apiPackageMemberPrice({package_id:this.id}).then(res=>{
			this.items = res.levelList
		})
	}
	
	getPackageDetail() {
	    this.loading = true
	    apiPackageDetail(this.id).then((res: any) => {
	        this.form = res
	        this.loading = false
	    })
	}

    handleInputDiscount(event: any, item: any) {
        let discount = event
        if (isNaN(discount) || discount < 0) discount = 0;
        if (discount > 10) discount = 10;
        item.discount = discount;
        item.sell_price = (item.total * (discount / 10)).toFixed(2);
    }

    handleInputPrice(event: any, item: any) {
        let price = event
        if (isNaN(price) || price < 0) price = 0;
		if ( price > item.total ) price = item.total
        item.sell_price = price;
        item.discount = ((price / item.total) * 10).toFixed(2);
    }

    created() {
        this.id = this.$route.query.id
		this.getPackageDetail()
		this.getPackagePrice()
    }
}
